@font-face {
  font-family: 'SB Sans Text';
  src: url('../../public/fonts/SBSansText-Light.woff'),
  url('../../public/fonts/SBSansText-Light.woff2');
  font-weight: 300;
}
@font-face {
  font-family: 'SB Sans Text';
  src: url('../../public/fonts/SBSansText-Regular.woff'),
    url('../../public/fonts/SBSansText-Regular.woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'SB Sans Text';
  src: url('../../public/fonts/SBSansText-Medium.woff'),
    url('../../public/fonts/SBSansText-Medium.woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'SB Sans Text';
  src: url('../../public/fonts/SBSansText-Semibold.woff'),
    url('../../public/fonts/SBSansText-Semibold.woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'SB Sans Text';
  src: url('../../public/fonts/SBSansText-Bold.woff'),
    url('../../public/fonts/SBSansText-Bold.woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'SB Sans Interface';
  src: url('../../public/fonts/SBSansUI-Regular.woff'),
    url('../../public/fonts/SBSansUI-Regular.woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'SB Sans Interface';
  src: url('../../public/fonts/SBSansUI-Semibold.woff'),
    url('../../public/fonts/SBSansUI-Semibold.woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'SB Sans Display';
  src: url('../../public/fonts/SBSansDisplay-SemiBold.woff'),
    url('../../public/fonts/SBSansDisplay-SemiBold.woff2');
  font-weight: 600;
}
